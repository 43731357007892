<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SEARCH REGULAR SAVINGS ACCOUNTS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row class="mt-6">
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              type="date"
              v-model="date_from"
              label="Date From"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              type="date"
              v-model="date_to"
              label="Date To"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="12"
            cols="12"
          >
            <v-combobox
              :append-icon="icons.mdiAccountSearchOutline"
              v-model="search"
              @keyup="searching($event.target.value)"
              placeholder="Search by (Account Name/Account #)"
              ref="memberCombobox"
              dense
              outlined
            ></v-combobox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Account #
            </th>
            <th class="text-center text-uppercase">
              Account Name
            </th>
            <th class="text-center text-uppercase">
              Balance
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
            <th class="text-center text-uppercase">
              Registered Member
            </th>
            <th class="text-center text-uppercase">
              View Passbook Data
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in search_items"
            :key="item.id"
          >
            <td>{{ item.account_no }}</td>
            <td class="text-center">
              {{ item.account_name }}
            </td>
            <td class="text-center">
              {{ item.balance_num }}
            </td>
            <td class="text-center">
              {{ item.status }}
            </td>
            <td class="text-center">
              {{ item.members }}
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="success"
                @click="view_data(item)"
              >
                {{icons.mdiEyeOutline}}
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="dialog_show" width="50%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">REGULAR SAVINGS DATA</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-simple-table dense>
          <template v-slot:top>
            <v-toolbar flat>
              <span class="subtitle-1">Date From  {{date_from}}</span>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <span class="subtitle-1">Date To {{date_to}}</span>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <span class="subtitle-1">SOA: <v-icon
                class="mr-2"
                color="warning"
                @click="print_data()"
              >
                                                {{icons.mdiPrinter}}
                                                </v-icon></span>
            </v-toolbar>
          </template>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">
                Date
              </th>
              <th class="text-center text-uppercase">
                Time
              </th>
              <th class="text-center text-uppercase">
                Deposit
              </th>
              <th class="text-center text-uppercase">
                Withdraw
              </th>
              <th class="text-center text-uppercase">
                Balance
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in passbook_data"
              :key="item.id"
            >
              <td>{{ item.date }}</td>
              <td class="text-center">
                {{ item.time }}
              </td>
              <td class="text-center">
                {{ item.deposit }}
              </td>
              <td class="text-center">
                {{ item.withdrawal }}
              </td>
              <td class="text-center">
                {{ item.balance }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mdiEyeOutline, mdiPrinter} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import moment from "moment";

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiEyeOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        dialog_show: false,

        saving: false,
        alert: false,
        alert_message: '',

        date_from: '',
        date_to: '',

        search: '',
        search_member: '',
        search_items: [],
        balance: 0,
        balance_num: 0,

        account_id: '',
        account_no: '',
        account_name: '',
        amount: 0,

        status: '',

        passbook_data: [],
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text','company_logo']),
      ...mapGetters('authentication', ['user_id', 'branch_id', 'month_start', 'month_end', 'month_of','branch','branch_address','branch_contact_no']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('staff', ['search_staff_by_category']),
      ...mapActions('regular_savings_info', ['search_members_have_savings_account']),
      ...mapActions('regular_savings_data', ['view_passbook_data']),
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_members_have_savings_account({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      view_data(data) {
        this.account_no = data.account_no
        this.account_name = data.account_name
        this.view_passbook_data({
          account_name: data.account_name,
          account_no: data.account_no,
          date_from: this.date_from,
          date_to: this.date_to
        })
          .then(response => {
            this.passbook_data = response.data
            this.dialog_show = true
          })
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        array.push(
          [
            {text: 'DATE', alignment: 'center', style: 'label'},
            {text: 'TIME', alignment: 'center', style: 'label'},
            {text: 'DEPOSIT', alignment: 'center', style: 'label'},
            {text: 'WITHDREW', alignment: 'center', style: 'label'},
            {text: 'BALANCE', alignment: 'center', style: 'label'},
          ]
        )
        if (this.passbook_data.length > 0) {
          this.passbook_data.forEach(function (item) {
            array.push(
              [
                {text: item.date, alignment: 'center'},
                {text: item.time, alignment: 'center'},
                {text: item.deposit, alignment: 'center'},
                {text: item.withdrawal, alignment: 'center'},
                {text: item.balance, alignment: 'center'},
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'STATEMENT OF ACCOUNT (SAVINGS)', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Account Name: ' + this.account_name, style: 'main_info'},
            {text: 'Account #: ' + this.account_no, style: 'main_info'},
            {text: 'Date From: ' + this.date_from, style: 'main_info'},
            {text: 'Date To: ' + this.date_to, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [100, 50, 50, 50,50],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 8,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
